import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import apartmentStoreModule from '../apartmentStoreModule';

export default function useApartmentList() {
  const APARTMENT_STORE_MODULE_NAME = "apartment";

  // Register module
  if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
    store.registerModule(APARTMENT_STORE_MODULE_NAME, apartmentStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
      store.unregisterModule(APARTMENT_STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();
  const { t } = useI18nUtils();
  const blankItem = {
    name: '',
    shortName: '',
    location: null,
    province: null,
    district: null,
    ward: null,
    address: '',
    active: true,
    paymentDay: 5,
    fees: [],
    tingee: null,
    invoiceTemplate: null,
    contractTemplate: null,
    cashbooks: [],
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'apartment') && !vm.$can('delete', 'apartment');

  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '80px',

    },
    {
      label: t('Thao tác'),
      field: 'action',
      width: '80px',
      tdClass: 'text-center',
      sortable: false,
      hidden: isHidden,
    },
    {
      label: t('Tên tòa nhà'),
      field: 'name',
      width: '200px',
    },
    {
      label: 'Số phòng',
      field: 'numberRooms',
      tdClass: 'text-center',
      type: 'number',
      width: '100px',
    },
    {
      label: t('Địa chỉ'),
      field: 'fullAddress',
      width: 'auto',
      thClass: 'tbl-address',
    },

    {
      label: t('Trạng thái'),
      field: 'active',
      tdClass: 'text-center',
      width: '110px',
      type: 'boolean',
    },

  ];
  const analytics = ref();
  const rows = ref([]);
  // filter
  const active = ref(null);
  const location = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call

  const fetchAnalytics = () => {
    store.dispatch('apartment/fetchAnalytics').then(response => {
      analytics.value = response.data;
    }).catch(error => {
      toastification.showToastError(error);
      isLoading.value = false;
    });
  };
  const fetchApartments = () => {
    store
      .dispatch('apartment/fetchApartments', serverParams.value)
      .then(response => {
        const { items, total } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };
  const fetchData = () => {
    isLoading.value = true;
    fetchApartments();
    fetchAnalytics();
  };
  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('apartment/importApartments', formData)
      .then(() => {
        // fetchData();
        toastification.showToastSuccess('Vui lòng đợi chút, hệ thống đang xử lý file của bạn');
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('apartment/exportApartments', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteApartments = apartments => {
    store
      .dispatch('apartment/deleteApartments', {
        ids: apartments.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onEditItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(active, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.active = val.value;
    } else {
      delete filter.active;
    }
    updateParams({ filter });
  });
  watch(location, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.locationId = val.id;
    } else {
      delete filter.locationId;
    }
    updateParams({ filter });
  });

  return {
    analytics,
    item,
    columns,
    rows,
    active,
    location,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    t,
    fetchData,
    fetchApartments,
    deleteApartments,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    importData,
    exportData,
  };
}
